export default {
    "登录失效，请重新登录": "Login failed, please log in again",
    "代购中心": "Purchasing Center",
    "订单列表": "Order List",
    "代购售后管理": "Agency purchase and after-sales management",
    "地址管理": "Address Management",
    "我的收藏": "My Collections",
    "申请成为采购商": "Apply to become a purchaser",
    "采购商服务中心": "Purchasing Service Center",
    "采购列表": "Procurement List",
    "采购售后管理": "Procurement and after-sales management",
    "采购服务规则": "Procurement Service Rules",
    "采购入账记录": "Purchase entry records",
    "个人主页": "Personal homepage",
    "财务管理": "financial management",
    "余额充值": "Balance recharge",
    "银行卡列表": "Bank Card List",
    "提现地址列表": "Withdrawal address list",
    "提现": "Withdrawal",
    "消费记录": "Consumption records",
    "同步订单管理": "Synchronize order management",
    "创建appKey": "Create appKey",
    "更新回调地址": "Update callback address",
    "解决方案": "Solution",
    "招募供应商": "supplier",
    "登录": "Login",
    "立即免费开通": "Open a store for free",
    "公司电话": "Company Office Number",
    "15038350530": "fifteen billion thirty-eight million three hundred and fifty thousand five hundred and thirty",
    "公司邮箱": "Company email",
    "97668216@qq.com": "97668216@qq.com",
    "公司地址": "Company Address",
    "河南省郑州市金水区花园路正弘中心1417": "1417 Zhenghong Center, Huayuan Road, Jinshui District, Zhengzhou City, Henan Province",
    "联系我们": "contact us",
    "微信号": "WeChat signal",
    "加入我们": "Join us",
    "关于我们": "About us",
    "隐私政策": "Privacy Policy",
    "帮助中心": "Help Center",
    "请输入电子邮件": "Please enter an email",
    "请输入登陆密码": "Please enter the login password",
    "电子邮件": "Email",
    "登录密码": "Login password",
    "忘记密码": "Forgot password",
    "提交": "Submit",
    "还没有账号": "No account yet",
    "注册": "register",
    "全球贸易平台！": "Global Trade Platform!",
    "使用taocarts建立您的在线业务在全球任何地方销售您的产品": "Use taocarts to establish your online business and sell your products anywhere in the world",
    "中国·郑州·总部": "Zhengzhou, China Headquarters",
    "售前手机": "Pre sales mobile phone",
    "合作邮箱": "Cooperative email",
    "郑州全搜索计算机技术服务有限公司": "Zhengzhou Quansearch Computer Technology Service Co., Ltd",
    "跨境行业全链路解决方案软件服务商": "Cross border industry full link solution software service provider",
    "郑州全搜索计算机技术服务有限公司（www.zzqss.com）（简称郑州全搜索）是一家以互联网信息技术开发和物联网IOT开发和服务为核心，为企业提供现代的网络服务公司。": "Zhengzhou Quan Search Computer Technology Service Co., Ltd. (www.zzqss. com) (referred to as Zhengzhou Quan Search) is a modern network service company that focuses on the development of internet information technology and IoT development and services, providing enterprises with modern network services.",
    "郑州全搜索计算机技术服务有限公司依托自身完善的服务体系，强大的技术支持力量，以及丰富的经验、信息资源和市场运作实力，使公司得到迅猛发展，并拥有一支富有创造力、充满激情、团结向上的队伍。": "Zhengzhou Quan Search Computer Technology Service Co., Ltd. relies on its well-established service system, strong technical support, rich experience, information resources, and market operation strength to achieve rapid development, and has a team full of creativity, passion, and unity.",
    "郑州全搜索设立专门的产品研发团队，探索国际领先的互联网点上模式，扑捉未来市场的需求，将其转化为自己的产品，与互联网各领域内从业者与专家保持密切沟通，形成全搜索独特的市场敏锐和前瞻性，开发出多套国内领先国际上普遍使用的跨境电商系统。": "Zhengzhou Quan Search has established a dedicated product research and development team to explore internationally leading internet point models, capture future market demands, and transform them into its own products. It maintains close communication with practitioners and experts in various fields of the internet, forming a unique market sensitivity and foresight of Quan Search, and has developed multiple sets of leading cross-border e-commerce systems commonly used domestically and internationally.",
    "公交站：花园路农科路": "Bus stop: Huayuan Road Nongke Road",
    "地铁站：花园路农科路": "Subway Station: Huayuan Road Nongke Road",
    "在这里开启你的全球生意": "Start your global business here",
    "您的想法，taocarts为您实现": "Your idea, Taoarts will implement it for you",
    "快速搭建你的在线商店": "Quickly build your online store",
    "是一款免费的易于使用的跨境在线商店系统，可以将您的商品销往全世界。": "It is a free and easy-to-use cross-border online store system that can sell your products worldwide.",
    "taocarts在线商店支持全球各种语言，全球各种货币。": "Taoarts online store supports various languages and currencies worldwide.",
    "taocarts在线商店五大优势": "Five Advantages of Taoarts Online Store",
    "完全免费": "Completely free",
    "taocarts店铺完全免费使用，无月租，无提成，无手续费": "Taoarts store is completely free to use, with no monthly rent, commission, or handling fees",
    "完美适配移动端": "Perfect adaptation to mobile devices",
    "专为移动端定制模板，用户体验更好": "Customized templates for mobile devices for better user experience",
    "全球本地收款": "Global Local Collection",
    "支持全球本地化收款方式，多种支付渠道整合": "Support global localized payment methods and integrate multiple payment channels",
    "一键对接全球配送": "One click docking with global delivery",
    "支持全球数百家国际物流一键绑定，自动履单。服务采购质检寄送": "Supports one click binding and automatic fulfillment of orders for hundreds of international logistics companies worldwide. Service procurement, quality inspection and delivery",
    "海量商品导入": "Massive product import",
    "支持淘宝1688官方接口几十亿商品导入，稳定快速。": "Supporting the import of billions of products through the official Taobao 1688 interface, stable and fast.",
    "帮助全球商家探索新型销售模式": "Help global merchants explore new sales models",
    "零售者": "Retailers",
    "适用于线上零售商": "Suitable for online retailers",
    "小成本开店": "Opening a low-cost store",
    "批发商": "wholesaler",
    "为想做中国商品批发的": "For those who want to do wholesale of Chinese goods",
    "创业者提供解决方案": "Entrepreneurs provide solutions",
    "商业公司": "Commercial companies",
    "定制专属业务模式": "Customized exclusive business model",
    "在全球销售中国商品": "Selling Chinese goods globally",
    "物流企业": "Logistics enterprises",
    "轻松拓展业务范围": "Easily expand business scope",
    "中国商品销售触手可得": "China's product sales tentacles are available",
    "拓展渠道难？建站成本高？引流难？销量低？无效率？无成交？": "Is it difficult to expand channels? Is the cost of building a website high? Difficulty in drainage? Low sales? Inefficient? No transaction?",
    "Taocarts一站式全球跨境电商平台，洞察行业最新动态，解决供应商出海痛点": "Taoarts is a one-stop global cross-border e-commerce platform that provides insights into the latest industry trends and addresses the pain points of suppliers going overseas",
    "功能展示（部分）": "Functional display (partial)",
    "建店周期": "Store building cycle",
    "是否有店铺成本": "Is there a store cost",
    "店铺装修": "Shop decoration",
    "店铺冻结": "Store freeze",
    "店铺语言": "Store language",
    "如何选品上架": "How to select products for listing",
    "选品来源": "Source of selection",
    "如何收款": "How to receive payment",
    "如何处理订单": "How to process orders",
    "如何发货": "How to ship",
    "如何添加文章说明": "How to add an article description",
    "店铺推广": "Store promotion",
    "1-2周": "1-2 weeks",
    "有，1500$/月": "Yes, $1500 per month",
    "自己学习配置&购买模板": "Learn to configure and purchase templates on your own",
    "会": "meeting",
    "多平台切换": "Multi platform switching",
    "支持21种语言": "Supports 21 languages",
    "自己选品&上架": "Choose your own products&put them on the shelves",
    "自己找货，手动添加&借助收费应用选品": "Find your own goods, add them manually&select products with a paid app",
    "需有自己的paypal账户": "Need to have your own PayPal account",
    "自己采购": "Self procurement",
    "自己对接物流": "Connect with logistics on your own",
    "接入shopify博客": "Accessing Shopify Blog",
    "5分钟": "5 minutes",
    "无": "nothing",
    "免费模板&自定义模板&定制化开发&购买模板": "Free templates&custom templates&customized development&purchase templates",
    "不会": "No",
    "一个平台全部搞定": "One platform is all taken care of",
    "支持所有语言": "Supports all languages",
    "taocarts选品&上架": "Taoarts Selection&Launch",
    "taocarts平台一键上传淘宝、1688平台商品": "One click upload of Taobao and 1688 platform products on Taoarts platform",
    "可支持任何收款方式": "Can support any payment method",
    "自动采购": "Automatic procurement",
    "taocarts平台搞定": "Toocarts platform takes care of it",
    "taocarts平台自定义编辑": "Taoarts platform custom editing",
    "建立在线商店": "Establishing an online store",
    "您的商店，您来做主": "Your store, you are in charge",
    "建立在线店面": "Establishing an online storefront",
    "只需要提交相关信息，Taocarts自动为您创建商店，把您想要销售中国商品的愿景变为现实，您将会是下一个大卖家": "Simply submit relevant information and Taoarts will automatically create a store for you, turning your vision of selling Chinese products into a reality. You will be the next big seller",
    "在线创建商店": "Create a store online",
    "打造您想要的品牌": "Create the brand you want",
    "由专业的设计师团队创建的精美模板,可在社区自定义装扮，打造个性小店": "Exquisite templates created by a professional team of designers can be customized in the community to create personalized small shops",
    "开通金流物流": "Activate Jinliu Logistics",
    "使用taocarts给您提供的全球金流物流渠道，轻松开通您自己的金流物流渠道。": "Use the global Gold Flow logistics channels provided by Taoarts to easily open your own Gold Flow logistics channels.",
    "在任何时间地点销售您的产品": "Sell your products at any time and place",
    "使用taocarts在线商店，可以分享到各大社交平台，集成taocarts销售渠道，将您商店中的商品销往世界各地": "Using the Taoarts online store, you can share on various social platforms, integrate Taoarts sales channels, and sell your products in your store to various parts of the world",
    "开始销售": "Start selling",
    "简单的商品上传": "Simple product upload",
    "全球最方便快捷的商品上传方式": "The most convenient and efficient way to upload products globally",
    "只需要在Taocarts网站选择好想要销售的商品，点击就能一键上传到你的商店，销售什么商品由你来决定": "Just select the products you want to sell on the Taoarts website and click to upload them to your store with just one click. It's up to you to decide what products to sell",
    "成千上万的优质商品任您选择": "Thousands of high-quality products to choose from",
    "快速可靠的支付方式": "Fast and reliable payment methods",
    "提供安全快捷的支付方式": "Provide safe and fast payment methods",
    "您的顾客在选择好商品之后，可以选择taocarts集成的当地的安全支付方式为您快捷收款，解决您的跨境收款难题。": "After selecting the product, your customer can choose the local secure payment method integrated with TaoCarts to quickly receive payment and solve the problem of cross-border payment for you.",
    "抓住每一次生意的机会": "Seize every business opportunity",
    "灵活的物流渠道选择": "Flexible logistics channel selection",
    "灵活的物流渠道选择，把货物安全的交付给您的顾客": "Flexible logistics channel selection to safely deliver goods to your customers",
    "你可以选择使用taocarts集成的全球物流渠道，可以安全快捷的将您的货物交付给您的全球顾客。": "You can choose to use taocarts integrated global logistics channels to safely and quickly deliver your goods to your global customers.",
    "给顾客提供优质的服务": "Provide high-quality service to customers",
    "内置营销工具": "Built in marketing tools",
    "第一次开店也能轻松完成业绩成倍增长": "Opening a store for the first time can easily achieve double growth in performance",
    "使用我们的营销工具": "Using our marketing tools",
    "接触潜在顾客": "Contact potential customers",
    "使用社区营销、推广联盟、积分工具等寻找新的购物者": "Using community marketing, promotion alliances, point tools, etc. to find new shoppers",
    "与客户互动": "Interacting with Customers",
    "自定义电子邮件营销内容，智能AI工具，随时随地和客户联系": "Customize email marketing content, intelligent AI tools, and connect with customers anytime, anywhere",
    "数据分析": "Data analysis",
    "掌握会员数据，分析受众并持续进行客户洞察，拉新留存转化": "Mastering member data, analyzing audience, and continuously conducting customer insights, recruiting, retaining, and transforming",
    "履行每个订单": "Fulfill each order",
    "您商店中所产生的每一个订单，都会由Taocarts帮您完整履单": "Every order generated in your store will be fully fulfilled by Taoarts",
    "运营监督": "Operational supervision",
    "Taocarts会跟踪付款和查看实时业务，因此您可以更专注于您的店铺优化": "Taoarts tracks payments and views real-time business, so you can focus more on building your business",
    "资金管理": "fund management",
    "为商家设置专属的资金池，一键查看您的财务信息": "Set up a dedicated fund pool for merchants to view your financial information with just one click",
    "超百万个企业家选择Taocarts创建业务商店": "Over a million entrepreneurs choose Taoarts to create a business store",
    "万": "ten thousand",
    "全球商户数量": "Number of global merchants",
    "来自国家": "From country",
    "占全球电子商务总量": "Share in the total global e-commerce volume",
    "亿": "Billion",
    "经济活动量": "Economic activity volume",
    "点击免费开通": "Click to open for free",
    "无论你是谁": "No matter who you are",
    "任何人都可以轻松经营taocarts商店": "Anyone can easily run taocarts stores",
    "Taocarts.shop用户隐私政策": "Taocarts.shop User Privacy Policy",
    "Taocarts.shop(以下简称“我们”)非常重视对用户个人隐私和个人信息安全的保护。我们将根据现行法律和政策采取相应的管理和安全保护措施，并制定本隐私政策(以下简称“本政策”)，同时提醒您:": "Taocarts. shop (hereinafter referred to as ‘we’) attaches great importance to protecting users' personal privacy and information security. We will take corresponding management and security measures in accordance with current laws and policies, and formulate this privacy policy (hereinafter referred to as ‘this policy’), while reminding you:",
    "本隐私政策适用干Taocarts.shop提供的产品或服务，包括您访问Taocarts.shop网站和/或登录相关应用程序时Taocarts.shop提供的服务": "This privacy policy applies to the products or services provided by Taocarts.shop, including the services provided by Taocarts.shop when you visit the Taocarts.shop website and/or log in to related applications",
    "请注意，本政策不适用于其他第三方提供给您的服务，此类服务适用第三方声明的隐私政策。": "Please note that this policy does not apply to services provided to you by other third parties, and such services are subject to the privacy policy declared by the third party.",
    "在您使用Taocarts.shop产品和/或服务之前，请阅读并确认您已完全理解本政策。使用由Taocarts.shop提供的产品和/或服务即表明您已完全理解并同意本政策。如果您有任何问题，意见或建议，请发送电子邮件至:97668216@qq.com或致电我们的客服。": "Before using Taocarts.shop products and/or services, please read and confirm that you fully understand this policy. By using the products and/or services provided by Taocarts.shop, you fully understand and agree to this policy. If you have any questions, comments or suggestions, please send an email to: 97668216@qq.com Or call our customer service.",
    "一、隐私政策": "1、 Privacy Policy",
    "本部分将带您了解:": "This section will take you through:",
    "Taocarts.shop何时会收集您的个人信息(第2节);": "When will Taocarts.shop collect your personal information (Section 2);",
    "Taocarts.shop收集哪些个人信息(第3-6节);": "What personal information does Taocarts.shop collect (sections 3-6);",
    "Taocarts.shop为何使用您的个人信息，以及如何使用和保护您的个人信息(第7-12节);": "Why Taocarts.shop uses your personal information and how to use and protect it (sections 7-12);",
    "撤回/更正个人信息或处理与隐私政策有关的问题(第13-15节);": "Withdrawal/correction of personal information or handling issues related to privacy policies (Sections 13-15);",
    "Taocarts.shop何时会收集您的个人信息?": "When will Taocarts.shop collect your personal information?",
    "我们将/可能在以下情况下收集您的个人信息": "We will/may collect your personal information in the following circumstances",
    "当您注册为卖方和/或使用本服务或平台，或在平台上开立账户时；": "When you register as a seller and/or use this service or platform, or open an account on the platform;",
    "当您在线或以书面形式提交表格时，包括但不限于申请表或与我们的产品或服务相关的其他表格；": "When you submit forms online or in writing, including but not limited to application forms or other forms related to our products or services;",
    "当您为与我们互动而签署协议或提供其他文件或信息时，或当您使用我们的产品和服务时；": "When you sign agreements or provide other documents or information to interact with us, or when you use our products and services;",
    "当您通过电话(可被录音)即时通讯应用程序或软件、信件、传真、电子邮件、面对面会议、交媒体平台等方式与我们沟通时；": "When you communicate with us through instant messaging applications or software over the phone (which can be recorded), letters, faxes, emails, face-to-face meetings, media platforms, and other means;",
    "当您在我们的论坛提出问题或回答其他用户的问题时；": "When you ask questions or answer questions from other users on our forum;",
    "当您使用我们的电子服务，或通过我们网站上的应用程序或服务与我们沟通时，包括但不限于通过cookies(当您接触我们的应用程序或网站时，我们可能会设置)进行收集;": "When you use our electronic services or communicate with us through applications or services on our website, including but not limited to collecting through cookies (which we may set when you come into contact with our applications or websites);",
    "当您通过我们的服务进行交易时": "When you trade through our services",
    "当您向我们提供意见或投诉时": "When you provide us with opinions or complaints",
    "当您报名参加意赛和其他营销活动时": "When you sign up for Italian competitions and other marketing activities",
    "当您因任何原因向我们提交个人信息时，以上只是我们可能会收集您的个人信息的一部分情况": "When you submit personal information to us for any reason, the above is only a part of the situation where we may collect your personal information",
    "Taocarts.shop收集哪些个人信息?": "What personal information does Taocarts.shop collect?",
    "Taocarts.shop收集的个人信息包括但不限于": "The personal information collected by Taocarts.shop includes but is not limited to",
    "基本信息(包括您的姓名，年龄，生日，性别，国籍、地址，账单地址，电话号码，电子邮箱地址等)": "Basic information (including your name, age, birthday, gender, nationality, address, billing address, phone number, email address, etc.)",
    "身份信息(包括身份证，工作证、护照、驾驶证，其他身份证件等);": "Identity information (including ID card, work permit, passport, driver's license, other identity documents, etc.);",
    "面部特征;": "Facial features;",
    "网络身份信息(包括与上述内容相关的系统帐号，IP地址，电子邮件地址和密码):": "Network identity information (including system account, IP address, email address, and password related to the above content):",
    "财务信息(交易、银行账户及积分、优惠券，Taocarts.shop账户余额等):": "Financial information (transactions, bank accounts and points, coupons, Taocarts. shop account balances, etc.):",
    "上网记录(包括计算机IP地址、操作系统，网站浏览记录、软件使用记录、点击记录等);设备信息(包括硬件型号、设备MAC地址，操作系统类型、软件列表、设备唯一标识符(如:IME/androidID/IDFA/OPENUDID/GUID)、SIM卡的IMSI等);位置信息(包括出行信息、准确定位信息、住宿信息、经纬度等):": "Online records (including computer IP addresses, operating systems, website browsing records, software usage records, click records, etc.); Device information (including hardware model, device MAC address, operating system type, software list, device unique identifier (such as IME/androidID/IDFA/OPENUDID/GUID), SIM card's IMSI, etc.); Location information (including travel information, accurate positioning information, accommodation information, longitude and latitude, etc.):",
    "由第三方服务提供商开立的账户信息；": "Account information opened by a third-party service provider;",
    "用户在注册使用我们的服务或网站时，以及在使用我们的服务或网站过程中提供的任何其他信息;": "Any other information provided by users during the registration and use of our services or website;",
    "可以提供用户交互总结的信息。": "Can provide user interaction summary information.",
    "您承诺不向我们提交任何不正确或误导性的信息，并同意将该等信息的何不准确内容或事改内容告知我们。Taocarts.shop保留单方面要求提供进一步文件以核实您所提供的信息的权利。": "You promise not to submit any incorrect or misleading information to us, and agree to inform us of any inaccurate or modified content of such information. Taocarts.shop reserves the right to unilaterally request further documents to verify the information you provide.",
    "如果您不希望我们收集上述信息/个人信息，您可随时以书面形式通知个人信息安全专员，并选择不收集其些信息，有关选择不收焦些信息的更多详情，请参阅下文“如何撤销同意，删除请求访问或更正您所提供的信息?”一节，但请注意，选择不收集某些信息或撤销授权我们收集，使用或处理您的个人信息将影响您对我们服务和平台的使用。例如，如果您选择不收集位置信息，基于位置的功能将被禁用。": "If you do not want us to collect the above information/personal information, you can notify the Personal Information Security Officer in writing at any time and choose not to collect some of the information. For more details on choosing not to collect some of the information, please refer to the section ‘How to revoke consent, delete request access, or correct the information you provided?’ below. However, please note that choosing not to collect certain information or revoking authorization for us to collect it, The use or processing of your personal information will affect your use of our services and platforms. For example, if you choose not to collect location information, location based functionality will be disabled.",
    "其他信息的收集": "Collection of other information",
    "与大多数网站和移动应用程序一样，您的设备发送的信息可句括您浏览我们平台时weh服务器记录的有关您的数据，我们收集的信息可能包括但不限于:您的互联网通信协议(IP)地址，计算机/移动设备操作系统和浏览器类型，移动设备类型，移动设备特征，移动设备唯一设备标识符(UDID)或移动设备标识符(MEID)，参考网站地址(如有)，以及您在我们的网站和移动应用程序上访回的页面和访问时间，有时还包括帮助网站记录您的最后一次访问时间的“cookie”(可被浏览器禁用)。如果您已经登录，这些信息将与您的账户相关联。这些信息也包含在匿名统计中以帮助我们了解访问者如何使用我们的网站。": "Like most websites and mobile applications, the information sent by your device can include data about you recorded by Weh servers when you browse our platform. The information we collect may include but is not limited to: your Internet Communication Protocol (IP) address, computer/mobile device operating system and browser type, mobile device type, mobile device characteristics, Mobile Device Unique Device Identifier (UDID) or Mobile Device Identifier (MEID), referring to the website address (if any), as well as the pages and visit times you visited on our website and mobile applications, and sometimes including a ‘cookie’ that helps the website record your last visit time (which can be disabled by the browser). If you have already logged in, this information will be associated with your account. This information is also included in anonymous statistics to help us understand how visitors use our website.",
    "我们的移动应用程序可的会使用GPSWi-Fi和其他技术来收集关于您移动设备位置的准确信息，我们可能会出于一项或多项目的收售使用披露和/或处理上述数据，这些目的包括但不限于:基于位置的服务请求，根据您的位置向您发送相关内容，以及允许您通过我们移动应用程序中的服务与其他用户共享您的位置，对于大多数移动设备，您可通过设备来禁止我们访问您的位害信息，如果您对如何禁用移动设备定位服务有任何疑问，请与您的设备服务提供商或制造商联系。": "Our mobile application can use GPSWi Fi and other technologies to collect information about your mobile device",
    "Cookie的种类": "Types of Cookies",
    "基础的网站Cookies": "Basic website cookies",
    "这些Cookies收集的信息，在为您提供我们网站的服务并使用其中的一些功能时使用，例如我们需要知道您当前的登录状态以判断是否为会员。": "The information collected by these cookies is used when providing you with services on our website and using some of its features. For example, we need to know your current login status to determine whether you are a member.",
    "系统性分析Cookies": "Systematic Analysis Cookies",
    "这些Cookies收集的信息，以多种形式帮助我们了解如何使用我们的网站访问情况如何，或帮助我们为您定制我们的网站。": "These cookies collect information in various forms to help us understand how to use our website for access, or to help us customize our website for you.",
    "功能": "function",
    "用户登录": "User login",
    "通知公告": "Notice Announcement",
    "数据分析网站": "Data analysis website",
    "期限": "term",
    "永久": "permanent",
    "如何控制Cookies": "How to Control Cookies",
    "您可以设置或修改您的网页浏览器控件，以接受或拒绝Cookies，如果您选择拒绝Cookies，您仍然可以使用我们的网站，此时您在访问我们网站的一些功能和区域时可能会受限，您拒绝使用Cookies的方式会因浏览器的不同而不同，您可以通讨点击下面主流浏资器的链接来设置您的Cookies偏好。": "You can set or modify your web browser controls to accept or reject cookies. If you choose to reject cookies, you can still use our website. At this time, your access to some features and areas of our website may be limited. The way you refuse to use cookies may vary depending on the browser. You can set your cookies preferences by clicking on the link to the mainstream browser below.",
    "浏览和下载内容和广告": "Browse and download content and advertisements",
    "当您在我们的网站或通讨我们的服务浏览内容，广告和访问其他软件时，系统会向我们发送信息，这些信息与您浏览我们的网页或移动应用释放时发送的信息基本相同(包括但不限于IP地址，操作系统等);但与网页浏览不同的是，您的设备将向我们传输与您浏览的内容和广告相关的信息，和/或与我们的服务、安装在我们网站上的软件和访问时间相关的信息。": "When you browse content, advertisements, and access other software on our website or through our services, the system will send us information that is basically the same as the information you sent when browsing our webpage or releasing it on a mobile application (including but not limited to IP address, operating system, etc.); But unlike web browsing, your device will transmit to us information related to the content and advertisements you browse, and/or information related to our services, software installed on our website, and access times.",
    "Taocarts.shop如何使用您提供的信息?": "How does Taocarts.shop use the information you provided?",
    "我们可能出于以下一项或多项目的收集，使用，披露和/或处理您的个人信息:": "We may collect, use, disclose, and/or process your personal information for one or more of the following purposes:",
    "考虑和/或处理您与我们的申请/交易，或您通过本服务与第三方的交易或通信:": "Consider and/or process your application/transaction with us, or your transaction or communication with third parties through this service:",
    "管理、运营、提供和/或处理您对本服务和平台的使用和/或访问(包括但不限于记录您的偏好)，以及您与我们的关系和您开立的用户账户;": "Manage, operate, provide, and/or handle your use and/or access to this service and platform (including but not limited to recording your preferences), as well as your relationship with us and the user account you have opened;",
    "回应，处理或完成交易和/或满足您对特定产品和服务的要求，以及向您告知服务问题和账户操作异常:": "Respond, process or complete transactions and/or meet your requirements for specific products and services, as well as inform you of service issues and account operation abnormalities:",
    "在各区域执行我们的服务条款或任何适用的终端用户许可协议;": "Implement our service terms or any applicable end-user license agreements in various regions;",
    "保护人身安全、权利、财产安全或他人的安全;": "Protecting personal safety, rights, property safety, or the safety of others;",
    "识别和/或验证;": "Identification and/or verification;",
    "维护和管理不定期的软件更新和/或为确保本服务顺利运作所需的其他更新和支持；": "Maintain and manage irregular software updates and/or other updates and support required to ensure the smooth operation of this service;",
    "处理或促进客户服务，执行您的指示，处理或回应由您或代表您(或声称由您或代表您)提出的所有询问；": "Handle or promote customer service, execute your instructions, handle or respond to all inquiries raised by or on behalf of you (or claimed to be raised by or on behalf of you);",
    "为执行和/或管理您与我们的关系或您对本服务的使用，通过电话，短信和或传电子邮件和/或邮件或其他方式与您联系或沟通，但不限于向您传达与本服务有关的管理信息。您确认并同意，我们可能会通过邮寄信件、文件和通知与您沟通，在投递过程中，信封/邮件包裹外面可能会披露您的具体个人信息;": "To execute and/or manage your relationship with us or your use of this service, we will contact or communicate with you via phone, text message, or email, or other means, but not limited to conveying management information related to this service to you. You confirm and agree that we may communicate with you through mailing letters, documents, and notices. During the delivery process, your specific personal information may be disclosed outside the envelope/email package;",
    "允许其他用户在平台上与您互动或联系，包括当用户向您发送短信或在平台上发表关于您的评论时向您发出通知;": "Allow other users to interact or contact you on the platform, including notifying you when users send you text messages or post comments about you on the platform;",
    "开展研究分析和开发活动(包括但不限于数据分析问卷调查产品和服务开发和/或分析)，以分析您对我们服务的使用，改善我们的服务或产品和/或提高您的客户体验;": "Conduct research, analysis, and development activities (including but not limited to data analysis, questionnaire surveys, product and service development, and/or analysis) to analyze your use of our services, improve our services or products, and/or enhance your customer experience;",
    "允许审计和调查，以确定目标受众的规模和组成，并了解他们使用Taocarts.shop服务的体验;": "Allow audits and investigations to determine the size and composition of the target audience and understand their experience using Taocarts.shop services;",
    "出于营销和相关目的，向您发送Taocarts.shop(和/或其关联企业或关联公司)可能通过各种沟通方式销售;营销或推广的产品和/或服务(包括但不限于可能与Taocarts.shop合作的第三方的产品和/或服务，或第三方与Taocarts.shop共同提供的产品和/或服务)。您可以通过电子营销资料中的退订功随时取消订阅接收营销信息，我们可能会通过您的联系信息向您发送关于我们和我们关联公司的消息:": "For marketing and related purposes, Taocarts.shop (and/or its affiliated enterprises or companies) may be sent to you for sale through various communication methods; Products and/or services marketed or promoted (including but not limited to products and/or services of third parties that may collaborate with Taocarts.shop, or products and/or services jointly provided by third parties with Taocarts.shop). You can unsubscribe and receive marketing information at any time through the unsubscribe function in the electronic marketing materials. We may send you messages about us and our affiliated companies through your contact information:",
    "对法律程序作出反应或遭守相关司法管辖区的任何适用法律、政府要求或监管要求，包括但不限于道守任何法律规定下的、对Taocarts.shop或其关联公司或关联企业具有约束力的披露要求:": "Respond to legal proceedings or comply with any applicable laws, government requirements, or regulatory requirements of relevant jurisdictions, including but not limited to disclosure requirements that are binding on Taocarts.shop or its affiliates or affiliated enterprises under any legal provisions of Daoshou:",
    "生成统计数据或进行研究，以满足内部及法定报告需要和/或记录保存:": "Generate statistical data or conduct research to meet internal and statutory reporting needs and/or record keeping:",
    "根据法律或监管义务，或根据法律要求或我们制定的风险管理程序，讲行尽职调查或其他审查活动(包括但不限于背导调查)": "Conduct due diligence or other review activities (including but not limited to backtracking investigations) in accordance with legal or regulatory obligations, or in accordance with legal requirements or risk management procedures established by us",
    "审计本服务或Taocarts.shop的业务;": "Audit the business of this service or Taocarts.shop;",
    "防止或调查任何确实或导似的Taocarts.shop政策违反行为，欺诈行为，非法活动，疏忽或不当行为，无论这些行为是否与您使用本服务有关或因您与我们的关系而产生:": "Prevent or investigate any actual or suspected Taocarts.shop policy violations, fraudulent practices, illegal activities, negligence or misconduct, whether related to your use of this service or arising from your relationship with us:",
    "在您的管辖范围内或范围外存储，托管和备份您的个人信息(无论是用于灾后数据恢复还是其他目的)。": "Store, host, and back up your personal information (whether for post disaster data recovery or other purposes) within or outside your jurisdiction.",
    "处理和/促进业务资产的交易或可能的企业资产交易，其中的参与者包括Taocarts.shop，或仅包括Taocarts.shop的关联公司或关联企业，或Taocarts.shop和/或Taocarts.shop的任何或多家关联公司或关联企业，可能还有其他第三方组织参与交易。“业务资产交易”是指对组织或组织的任何部分的任何业务或资产的收购，出售、租赁，整台、合并或任何其他收购，处置或融资;": "Handling and/or promoting business",
    "我们将在现有技术允许的情况下采取各种合理和必要的安全措施(包括但不限于物理，技术和行政措施)，以确保您的个人信息在我们的系统中是安全可靠的，从而最大限度地降低丢失，误用、未经授权访问，披露和变更的风险，由于各种恶意手段和科学技术的不断突破，您理解并同意，即使我们尽最大努力采取上述措施，您的个人信息仍有可能被泄露，捐坏或丢失。Taocarts.shop不做与此相关的任何承诺或保证。": "We will take all reasonable and necessary security measures (including but not limited to physical, technical, and administrative measures) to ensure that your personal information is secure and reliable in our system, to minimize the risk of loss, misuse, unauthorized access, disclosure, and change, as permitted by existing technology. Due to various malicious means and continuous breakthroughs in science and technology, you understand and agree that, Even if we do our best to take the above measures, your personal information may still be leaked, donated, or lost. Taocarts.shop makes no promises or warranties related to this matter.",
    "我们不向未成年人推荐我们的服务，除非当地法律允许并经监护人同意，否则不允许未成年人注册账户或将其姓名、地址、电话号码和电子邮件地址等个人信息发送给我们。如果我们意外收集了关于未成年人的信息，我们会在知悉后立即删除，如果您认为我们可能不恰当地持有来自或关于未成年人的信息，请与我们联系。": "We do not recommend our services to minors, and unless permitted by local law and with the consent of our guardians, minors are not allowed to register accounts or send us personal information such as their name, address, phone number, and email address. If we accidentally collect information about minors, we will immediately delete it upon becoming aware. If you believe that we may improperly hold information from or about minors, please contact us.",
    "我们将按照有关隐私保护的法律法规和/或其他适用法律的规定保留您的个人信息，即如果我们有理由相信发生以下情况，则我们将销照您的个人信息或将其要名化外理:(1)该等个人信息的保留已不再符合收集个人信息的目的:(2)不再要要出于任何法律或业务目的而保留个人信息:以及(3)该等个人信息的进一步保留不受任何其他合法权益的保证。如果您停止使用我们的平台或您使用平台和/或服务的权利被终止，我们将按照适用法律的要求，根据本隐私政策和相关院私法规定的义务继续处理您的个人信息。": "We will retain your personal information in accordance with relevant privacy protection laws and regulations and/or other applicable laws, that is, if we have reason to believe that:, We will either revoke or rename your personal information: (1) the retention of such personal information is no longer in line with the purpose of collecting personal information; (2) it is no longer necessary to retain personal information for any legal or business purposes; and (3) further retention of such personal information is not guaranteed by any other legal rights. If you cease to use our platform or your right to use the platform and/or services is terminated, we will continue to process your personal information in accordance with applicable laws and obligations under this privacy policy and relevant private laws.",
    "Taocarts.shop会向第三方披露其从访问者处收集的信息吗?": "Will Taocarts.shop disclose the information it collects from visitors to third parties?",
    "在开展业务的过程中，我们将/可能需要出于一项或多项目的，将处理后的您的个人信息披露给第三方服务提供商、代理商和/或我们的关联公司或关联企业和/或其他第三方(无论是否位千中国)，该等第三方服务提供商，代理商和/或关联公司或关联企业和/或其他第三方可代表我们或以其他方式出于上述一项或多项目的处理您的个人信息。第三方包括但不限于": "In the process of conducting business, we will/may need to disclose your processed personal information to third-party service providers, agents, and/or our affiliated companies or affiliated enterprises, and/or other third parties (whether located in China or not) for one or more projects, Agents and/or affiliated companies or affiliated enterprises and/or other third parties may represent us or otherwise process your personal information for one or more of the above purposes. Third parties include but are not limited to",
    "我们的子公司，关联公司和关联企业:": "Our subsidiaries, affiliated companies, and affiliated enterprises:",
    "出于上述一项或多项目的而使用我们网站的用户": "Users who use our website for one or more of the above projects",
    "我们用于支持我们业务的承包商，代理商，服务提供商和其他第三方服务提供商“授权合作伙伴”)，包括但不限于向我们提供管理或其他服务的组织，如:邮寄公司，支付服务提供商，电信公司。信息技术公司和数据中心:我们仅会出于本隐私政策的合法，合理、必要，具体和明确的目的而共享您的信息，授权合作伙伴只能访问其履行职责所需的信息，目不得将此类信息用千任何其他目的。": "We are authorized partners of contractors, agents, service providers, and other third-party service providers that support our business, including but not limited to organizations that provide us with management or other services, such as mailing companies, payment service providers, and telecommunications companies. Information technology companies and data centers: We will only share your information for the legitimate, reasonable, necessary, specific, and clear purposes of this privacy policy. Authorized partners can only access the information they need to perform their duties, and may not use such information for any other purpose.",
    "合并、剥离、改组、重组、解散或以其他方式出售或转让(无论是以持续经营的形式，还是作为破产、清算或类似程序的一部分)Taocarts.shop的部分或全部资产情况下的接收方，或Taocarts.shop的任何其他继承者，其中，Taocarts.shop持有的，有关服务卖方的个人信息也在转计资产中;或Taocarts.shop或其任何关联公司或关联企业参与的业务资产交易的对手方:": "Merger, divestment, reorganization, dissolution, or other means of dissolution",
    "Taocarts.shop会把您的个人信息传输到国外吗?": "Will Taocarts.shop transmit your personal information overseas?",
    "您的个人数据和/或信息可能会被传输到您所在国家/地区以外的地方存储或处理。在大多数情况下，您的个人信息是在美国处理的，因为我们的服务器位于美国，我们在这里运行我们的中央数据库。Taocarts.shop只会在符台相关隐私法律法规的情况下将您的信息传输到国外。": "Your personal data and/or information may be transferred for storage or processing outside of your country/region. In most cases, your personal information is processed in the United States because our server is located in the United States, where we run our central database. Taocarts.shop will only transfer your information to foreign countries in compliance with Taiwan's relevant privacy laws and regulations.",
    "如何撤销同意，删除，请求访问或更正您所提供的信息?": "How to revoke consent, delete, request access, or correct the information you provide?",
    "选择不收集些信息以及撒销同意": "Choose not to collect some information and agree to sell",
    "您可通过发送电子邮件至97668216@qq.com联系个人信息安全专员，选择不收集其些信息，并不再同意我们收集，使用和/或披露我们持有或控制的您的个人信息，我们将按照本隐私政策以及隐私法和其他活用法律下的相关义务行事，然而，通过选择不收集基些信息/撤销您的同意，即代表您同意，我们将无法继续向您提供我们的服务，并可能要求您终止您与我们之间的现有关系和/或有关服务的合同。": "You can send an email to 97668216@qq.com Contact the Personal Information Security Officer, choose not to collect some of your information, and no longer agree to our collection, use, and/or disclosure of your personal information that we hold or control. We will act in accordance with this privacy policy and relevant obligations under privacy laws and other applicable laws. However, by choosing not to collect basic information/revoking your consent, we will be unable to continue providing you with our services, And may require you to terminate your existing relationship with us and/or contracts related to services.",
    "请求访问和/或更正您的个人信息。": "Request access and/or correction of your personal information.",
    "如果您已向我们注册，您可以通过您的账户经理或平台的相应页面访问和/或更正我们目前持有或控制的您的个人信息。如果您没有账户，您可以通过提交书面请求，请求访问和/或更正我们目前持有或控制的个人信息。您必须得供足够的信息，以便我们确定您的身份和您的请求的性质，从而处理您的请求。请通过发送电子邮件至97668216@qq.com(“个人信息安全专员”)提交您的书面请求": "If you have registered with us, you can access and/or correct your personal information that we currently hold or control through the corresponding page of your account manager or platform. If you do not have an account, you can request access and/or correction of personal information that we currently hold or control by submitting a written request. You must provide sufficient information so that we can determine your identity and the nature of your request in order to process it. Please send an email to 97668216@qq.com (Personal Information Security Officer) Submit your written request",
    "在某些情况下，我们会可能会向您收取合理的费用，以处理您访问个人信息的请求，如果需要收费，我们将以书面形式(包括电子邮件)向您提供项目和价格。请注意，除非您同意付费，否则我们无需回应或处理您的访问请求": "In some cases, we may charge you a reasonable fee to process your request to access personal information. If a fee is required, we will provide you with the project and price in writing (including email). Please note that unless you agree to pay, we do not need to respond to or process your access request",
    "根据隐私法的规定，在既定情况下，企业有权在被要求和/或授权时拒绝更正您的个人信息，我们保留拒绝更正您的个人信息的权利": "According to the privacy law, under established circumstances, companies have the right to refuse to correct your personal information when requested and/or authorized. We reserve the right to refuse to correct your personal information",
    "如有问题，疑虑或投诉，请与我们联系": "If you have any questions, doubts or complaints, please contact us",
    "如果您对我们的隐私实践或与本服务相关的交易有任何问题或疑虑，请随时与我们联系": "If you have any questions or concerns about our privacy practices or transactions related to this service, please feel free to contact us at any time",
    "我们的隐私政策可能会变更": "Our privacy policy may change",
    "随着Taocarts.shop业务的发展，我们将更新本隐私政策，为您提供更好的服务。但是，未经您明确同意，您在本政策下的权利不会受到限制，我们将在Taocarts.shop网站上公布我们隐私政策的任何变更。": "With the development of Taocarts.shop business, we will update this privacy policy to provide you with better services. However, without your explicit consent, your rights under this policy will not be restricted, and we will publish any changes to our privacy policy on the Taocarts.shop website.",
    "对于大变更，我们还将以更醒目的方式通知您(我们将通过包括但不限于电子邮件，短信或在网页上显示特别提醒等方式解释隐私政策的具体变更)。": "For major changes, we will also notify you in a more prominent manner (we will explain specific changes to our privacy policy through methods such as but not limited to emails, text messages, or displaying special reminders on the webpage).",
    "本隐私政策涉及的重大变更包括但不限于": "The significant changes involved in this privacy policy include",
    "《个人信息安全影响评估报告》中指出的高风险。我们还将在Taocarts.shop上存档本隐私政策的旧版本，供您参考。": "The high risk identified in the Personal Information Security Impact Assessment Report. We will also archive the old version of this privacy policy on Taocarts.shop for your reference.",
    "填写您所售商品": "Fill in the product you are selling",
    "请输入您的电话号": "Please enter your phone number",
    "请输入您的邮箱": "Please enter your email address",
    "填写您的所在地": "Fill in your location",
    "填写您的企业信息": "Fill in your company information",
    "Taocarts一站式": "Taocarts One Stop",
    "全球跨境电商平台": "Global cross-border e-commerce platforms",
    "B2B、B2C垂直领域专家，旨在帮助供应商一站直达全球消费者，触达海量优质市场，把商品销往全球": "B2B and B2C vertical experts aim to help suppliers reach global consumers in one stop, reach massive high-quality markets, and sell their products globally",
    "即刻入驻，商机无限": "Immediate entry, unlimited business opportunities",
    "收录不限制品类，全球用户一站直达": "Inclusion of unlimited categories, one stop direct access for global users",
    "我们的优势": "Our Advantages",
    "有买家": "There are buyers",
    "已有月活7亿的海外消费群体": "An overseas consumer group with a monthly life of 700 million",
    "有资源": "Resources available",
    "金流、物流、营销、管理一站式搞定，你只需要把商品交给我们": "One stop solution of cash flow, logistics, marketing and management, you just need to hand over the goods to us",
    "有效率": "Efficiency",
    "多种自动化工具，赋能运营，智能AI助力": "Multiple automation tools empower operations with intelligent AI assistance",
    "有经验": "Experienced",
    "12年跨境经验，服务上千家企业，行业内高价值生态伙伴多": "12 years of cross-border experience, serving thousands of enterprises, with many high-value ecological partners in the industry",
    "taocarts渠道商": "Taoarts channel provider",
    "taocarts渠道商入驻流程": "Taoarts Channel Merchant Entry Process",
    "渠道商开通的客服联系方式": "Customer service contact information opened by channel providers",
    "全球贸易，让您像做国内贸易一样简单！": "Global trade makes it as easy as doing domestic trade!",
    "加入Taocarts，轻松完成全球销售": "Join Taoarts and easily complete global sales",
    "立即入驻": "Immediate entry",
    "留电咨询获取商机": "Stay in touch for business opportunities",
    "享受蓝海抢占先机": "Enjoy the blue ocean to seize the opportunity",
    "提交申请表": "Submit Application Form",
    "极简账号配备一件代发电商业务经营基本功能，包括绑定店铺，自动履单，自动提包等功能。新注册用户账号系统认设置为极简账号，在店铺经营过程中需进行订单操作，则默认授权由我们代运营团队代其处理，处理讨程中产生的服务或操作费用按Taocarts.shop官网公示的服务费用或事先双方协商的服务费用由系统自动扣缴或财务手动扣缴。": "The minimalist account is equipped with basic functions for e-commerce business operation, including binding stores, automatic fulfillment, and automatic bag picking. The system recognizes that the newly registered user account is set as a minimalist account. If an order operation is required in the process of store operation, we are authorized to handle it on behalf of the operation team by default. The service or operation costs incurred in processing the discussion are automatically withheld by the system or manually withheld by the finance department according to the service fees published on the official website of Taocarts.shop or the service fees negotiated by both parties in advance.",
    "您在注册，使用我们服务的过程中，请您提供合法，真实，有效，准确并完整的资料(相据实际操作票要，可能包括真实姓名，国籍，营业执照，联系方式，从事职业通讯地址等)。为了能将账户信息变动及时通知到您，以及更好保障您的账户安全，如该等资料发生变更，请您及时通知我们或者自行在Taocarts.shop后台做调整更新。": "During the process of registering and using our services, please provide legal, authentic, valid, accurate and complete information (which may include your real name, nationality, business license, contact information, professional communication address, etc., according to the actual operation ticket). In order to promptly notify you of changes in account information and better ensure the security of your account, if such information changes, please notify us in a timely manner or make adjustments and updates on your own in the Taocarts.shop backend.",
    "(三)存在如下情形时，我们可能会对您名下Taocarts.shop用户账户暂停或终止提供Taocarts.shop用户服务:": "（3） We may suspend or terminate the provision of Taocarts.shop user services to your Taocarts.shop user account under the following circumstances:",
    "您违反了法律法规的规定或本协议的约定。": "You have violated the provisions of laws and regulations or the provisions of this agreement.",
    "您的用户账户可能存在风险的(比如您的Taocarts.shop用户账户长期未被使用月存在一定安全隐患)。": "Your user account may be at risk (for example, your Taocarts.shop user account may have certain security risks if it has not been used for a long time).",
    "您在参加市场活动时涉及到侵权或违反商业道德，违反诚实信用原则。": "When participating in market activities, you are involved in infringement or violation of business ethics, violating the principle of good faith.",
    "您遭到他人投诉，且对方已经得供了一定证据。": "You have received a complaint from someone who has already provided certain evidence.",
    "您可能错误地操作他人账户，或者将他人账户进行了身份验证。": "You may have mistakenly operated on someone else's account or verified their identity.",
    "(四)Taocarts.shop用户服务规则": "（4） Taocarts.shop User Service Rules",
    "您用户账户的使用记录交易数据等均以我们的系统记录为准，如您对该等数据存有导议的，可及时向我们提出，我们会根据您提供的相关证据积极予以核实。": "The usage records, transaction data, etc. of your user account are based on our system records. If you have any objections to such data, please inform us in a timely manner and we will actively verify it based on the relevant evidence you provide.",
    "Taocarts.shop采用预付金额(账号充值)，后系统扣款、财务扣款/退款的方式来管理您的账户余额和资金流动，您在使用Taocarts.shop账号的相关服务时请确保账号余额充足。": "Taocarts.shop uses a prepaid amount (account recharge), followed by system deductions, financial deductions/refunds to manage your account balance and fund flow. When using Taocarts.shop account related services, please ensure that the account balance is sufficient.",
    "为了更好地向您提供服务，请您在使用Taocarts.shop用户服务过程中，遵守本协议内容，Taocarts.shop用户网站上出现的关于操作的提示及我们发送到您邮件的提示。": "In order to better provide you with services, please comply with the contents of this agreement, the prompts for operation appearing on the Taocarts.shop user website, and the prompts we send to you in our email during the use of Taocarts.shop user services.",
    "我们会以网站公告、电子邮件、站内信等方式向您发送通知，例如通知您交易进展情况，或者提示您进行相关操作，请您及时予以关注。": "We will send notifications to you through website announcements, emails, on-site messages, etc., such as notifying you of the progress of the transaction or prompting you to take relevant actions. Please pay attention to them in a timely manner.",
    "您需要自行承相使用我们服务期间的货币贬值，汇率波动等风险，您对我们代为收取(账户充值)或代为支付款项(用于买单打包等操作扣款)的金额享有对该等款项的管辖权和支配权。服务咨询与投诉": "You need to bear the risk of currency depreciation, exchange rate fluctuations, and other risks during the use of our services. You have jurisdiction and control over the amount we collect (account recharge) or make payments on our behalf (for deduction of payments such as purchasing and packaging). Service consultation and complaints",
    "在使用我们服务的过程中，如遇到问题，您可以通过拨打+8615038350530或者通过在线客服等方式联系我们，为了保护您及他人的合法权益，如您被他人投诉或投诉他人，我们可能会将您的姓名及身份信息联系方式，企业经营范围投诉相关内容等提供给管辖权的仲裁机关，以便及时解决投诉纠纷；同时，出于维护您或他人的生命、财产等重大合法权益但又很难得到您本人同意的情况下，我们可能会将您的姓名及身份信息、联系方式、企业经营范围、投诉相关内容等提供给争议相对方，但法律法规明确禁止提供的除外。": "In the process of using our services, if you encounter any problems, you can contact us by calling+86150383350530 or through online customer service. In order to protect the legitimate rights and interests of you and others, if you are complained about or complained about by others, we may provide your name and contact information, as well as the relevant content of the company's business scope complaint, to the competent arbitration authority for timely resolution of the complaint dispute; At the same time, in order to protect your or others' significant legitimate rights and interests such as life and property, but it is difficult to obtain your personal consent, we may provide your name and identity information, contact information, business scope, complaint related content, etc. to the other party in dispute, except for those explicitly prohibited by laws and regulations.",
    "(五)我们的承诺和声明": "（5） Our Commitment and Declaration",
    "您账号中收到的充值款项，会一直以余额的形式存储在您的Taocarts.shop账号中，我们不会挪作他用。": "The recharge funds received in your account will always be stored in the form of a balance in your Taocarts.shop account, and we will not use them for other purposes.",
    "您使用Taocarts.shop账号能享受一件代发和店捕代运营全部相关服务。": "You can enjoy all related services such as one shipment and store capture and operation using your Taocarts.shop account.",
    "(六)服务风险提示": "（6） Service Risk Reminder",
    "在使用我们的服务时，若您未遵从本协议，事先约定的协议(SOP)或相关网站说明，页面中的操作提示，规则，则我们有权拒绝为您提供相应服务，且我们免于承担损害赔信责任。": "When using our services, if you fail to comply with this agreement, the pre agreed agreement (SOP) or relevant website instructions, operating instructions, and rules on the page, we have the right to refuse to provide you with the corresponding services, and we are exempt from liability for damages.",
    "请您特别注意，如在Taocarts.shop用户网站上以页面标明或其他方式表明相关服务系由第三方提供，您在使用该服务过程中如有疑问或发生纠纷，请您与第三方协商解决。": "Please pay special attention. If you indicate on the Taocarts.shop user website that the relevant services are provided by a third party through page labeling or other means, if you have any questions or disputes during the use of this service, please negotiate with the third party to resolve them.",
    "Taocarts.shop销售的商品均采购自中华人民共和国，主要符合中华人民共和国有关商品质量、安全、卫生、环保、标识等标准，可能与您所在国家的标准存在差异或冲突，您须自行辨别并承担相关风险。": "The products sold by Taocarts.shop are all purchased from the People's Republic of China and mainly comply with the relevant standards of the People's Republic of China regarding product quality, safety, health, environmental protection, labeling, etc. There may be differences or conflicts with the standards of your country. You must identify and bear the relevant risks on your own.",
    "(七)服务费用": "（7） Service fees",
    "在您使用我们的服务时，我们有权向您收取服务或者操作费用，具体服务用以您使用我们服务时Taocarts.shop官方网站展示的价格或您与我们达成的其他书面协议价格为准。除非另有说明或约定，当您完成账户注册时起，则默认授权我们代为收取或代为扣缴您的账户余额作为平台使用的服务或者操作费用。": "When you use our services, we have the right to charge you service or operating fees. The specific service fees are based on the prices displayed on the Taocarts.shop official website when you use our services or other written agreements reached between you and us. Unless otherwise specified or agreed, from the time you complete account registration, we are authorized by default to collect or withhold your account balance as a service or operation for the platform to use",
    "从事可能侵害我们的服务系统、系统知识产权或数据的行为。": "Engaging in activities that may infringe upon our service systems, system intellectual property rights, or data.",
    "(四)账户仅限本人使用，承诺按流程开立和使用本人账户": "（4） The account is only for personal use, and I promise to open and use my account according to the process",
    "(五)您理解，我们的服务有赖于系统的准确运行及操作，若出现系统差错，故障等情形，您同意我们可以采取更正差错，扣划款项等适当纠正措施。": "（5） You understand that our services depend on the accurate operation and operation of the system. If there are system errors, malfunctions, and other situations, you agree that we can take appropriate corrective measures such as correcting errors and deducting payments.",
    "(六)您不得对我们的系统和程序采取反向工程手段进行破解，不得对上述系统和程序(包括但不限于源程序、目标程序、技术文档、服务器数据)进行复制、修改编译整合或算改，不得修改成增减我们系统的功能。": "（6） You are not allowed to use reverse engineering to crack our systems and programs, copy, modify, compile, integrate or calculate the above systems and programs (including but not limited to source programs, object programs, technical documentation, server data), or modify the functions of our system.",
    "(七)您不得自行或允许第三方通过非Taocarts.shop用户官方程序、机器、脚本、爬虫、或其他自动化的方法使用Taocarts.shop用户服务或获取Taocarts.shop用户服务相关数据。您仅可通过本人人工操作的方式或者Taocarts.shop官方代运营操作的方式来使用Taocarts.shop用户服务。": "（7） You may not use or allow third parties to use the Taocarts.shop user service or obtain data related to the Taocarts.shop user service through non official programs, machines, scripts, crawlers, or other automated methods. You can only use the Taocarts.shop user service through your own manual operation or through the official operation of Taocarts.shop.",
    "(一)用户权益保障承诺": "（1） User rights protection commitment",
    "我们一直事承“极致服务，客户第一”的原则，努力为您在国际电商经营中保驾护航。为保障您在使用服务时的合法权益，我们向您承诺保障您的知情权，自主选择权、公平交易权、受尊重权和客户信息安全权。": "We have always adhered to the principle of ‘ultimate service, customer first’ and strive to protect you in international e-commerce operations. To protect your legitimate rights and interests when using the service, we promise to protect your right to know, independent choice, fair trading, respect, and customer information security.",
    "(二)用户资金保障": "（2） User funding guarantee",
    "我们始终重视保护您的资金安全，承诺不挪用，占用用户资金。如您在使用我们服务的过程中，由于您的Taocarts.shop用户账户被盗用造成资金损失，我们将按照责任双方台同的约定为您提供资金保障和赔偿。": "We always attach great importance to protecting the security of your funds and promise not to misappropriate or occupy user funds. If you experience financial losses due to the theft of your Taocarts.shop user account during the use of our services, we will provide you with financial protection and compensation in accordance with the agreement between the responsible parties.",
    "(三)信息保护": "（3） Information Protection",
    "出于技术原因和提升您的用户体验需要，我们将通过Cookies技术，当您的网络浏览器访问Taocarts.shop服务或由我们或代表我们在其它网站上提供的其他内容时，自动收集并存储您在Taocarts.shop的使用，行为信息，并在经过数据脱敏使之不再指向，关联到您个人的身份信息时，自由使用脱敏后的纯商业数据。我们将始终高度重视用户的信息保护。未经您的同意，不会将您的个人资料，商业秘密等信息供给何第三方。如果您相删除cookies，或进一步了解收集的信息，我们希望您认真并完整党读我们的《隐私政策》，这将更有助于保障您的个人信息安全。": "For technical reasons and the need to improve your user experience, we will use Cookies technology to automatically collect and store your usage and behavior information on Taocarts.shop when your web browser accesses the Taocarts.shop service or other content provided by or on behalf of us on other websites. After data desensitization, it will no longer point to your personal identity information, and we will freely use the desensitized pure commercial data. We will always attach great importance to user information protection. Without your consent, your personal data, trade secrets, and other information will not be provided to any third party. If you would like to delete cookies or further understand the collected information, we hope that you will carefully and completely read our Privacy Policy, which will help ensure the security of your personal information.",
    "(一)免责条款": "（1） Disclaimer clause",
    "因下列原因导致我们无法正常提供服务，我们免于承担责任:": "We are exempt from liability for our inability to provide services normally due to the following reasons:",
    "我们的系统停机维护或升级;": "Our system is shut down for maintenance or upgrades;",
    "因台风，地震，洪水、雷电或恐怖袭击等不可抗力原因:": "Due to force majeure reasons such as typhoons, earthquakes, floods, lightning, or terrorist attacks:",
    "您的电子设备软硬件和通信线路，供电线路出现故障:": "The software, hardware, and communication lines of your electronic device, as well as the power supply line, have malfunctioned:",
    "因您操作不当或通过非经我们授权或认可的方式使用我们服务:": "Due to your improper operation or unauthorized or recognized use of our services:",
    "我们可能同时为您及您的竞争对手方提供服务，您同意对我们可能存在的该等行为予以明确豁免任何实际或潜在的利益冲突，并不得以此主张我们在提供服务时存在法律上的瑕疵。": "We may provide services to both you and your competitors, and you agree to provide clear exemptions from any actual or potential conflicts of interest that may arise from our actions, and may not claim any legal flaws in our provision of services.",
    "为了您更好地使用Taocarts.shop用户服务，您还需要阅读并遵守Taocarts.shop用户网站公示的其他协议或规则。前述协议或规则的部分条款被认定无效的，不影响其他内容的效力。": "In order for you to better use the Taocarts.shop user service, you also need to read and comply with other protocols or rules published on the Taocarts.shop user website. If some provisions of the aforementioned agreement or rules are deemed invalid, it shall not affect the validity of other contents.",
    "我们及关联公司的系统及Taocarts.shop网站上的内容，包括但不限于图片、网站架构、网站画面的安排、网页设计，均属于我们公司依法拥有的知识产权，包括但不限于商标权、专利权、商业秘密等。": "The systems of our affiliated companies and the content on the Taocarts.shop website, including but not limited to images, website architecture, website layout, and webpage design, all belong to the intellectual property rights legally owned by our company, including but not limited to trademark rights, patent rights, trade secrets, etc.",
    "非经我们或关联公司书面同意，请勿擅自使用，修改，反向编译，复制，公开传播，改变，散布，发行或公开发表BuckvDrop网站程序或内容。": "Without the written consent of us or our affiliated companies, please do not use, modify, reverse compile, copy, publicly distribute, alter, distribute, distribute, or publicly publish BuckvDrop website programs or content.",
    "尊重知识产权是您应尽的义务，如有违反，您需要承担损害赔偿责任。": "Respecting intellectual property rights is your obligation, and if there is a violation, you will be liable for damages.",
    "Taocarts.shop销售的商品主要符合中华人民共和国的相关知识产权，但因知识产权的地域性因素影响，可能会侵犯您所在国家的相关知识产权，您须自行辨别，并放弃向Taocarts.shop平台主张索赔的权利。": "The products sold by Taocarts.shop mainly comply with the relevant intellectual property rights of the People's Republic of China. However, due to regional factors of intellectual property rights, it may infringe on the relevant intellectual property rights of your country. You must identify and waive the right to claim compensation from the Taocarts.shop platform.",
    "如果任何组织或个人认为Taocarts.shop网站上的任何内容可能侵犯其知识产权，可以通过在线客服或邮箱97668216@qq.com及时通知我们，我们会在收到通知后核实，并删除侵权内容，但不承拍任何相关法律责任": "If any organization or individual believes that any content on the Taocarts.shop website may infringe their intellectual property rights, they can do so through online customer service or email 97668216@qq.com Notify us in a timely manner, and we will verify and delete the infringing content upon receipt of the notice, but will not assume any relevant legal liability",
    "本协议之效力、解释、变更、执行与争议解决均适用中华人民共和国法律。因本协议产生的争议，均应依照中华人民共和国法律予以处理。Taocarts.shop拥有和保留本协议的最终解释权。": "The validity, interpretation, modification, execution, and dispute resolution of this agreement shall be governed by the laws of the People's Republic of China. Any disputes arising from this agreement shall be resolved in accordance with the laws of the People's Republic of China. Taocarts.shop has and reserves the final interpretation right of this agreement.",
    "因本协议看行所产生的一切争议，双方应首先友好协商解决，如协商不成的，仟何一方有权向深圳国际仲裁院申请仲裁。仲裁地为中国广东省深圳市仲裁语言为中文。": "All disputes arising from the operation of this agreement shall be resolved through friendly consultation between both parties. If consultation fails, either party shall have the right to apply for arbitration to the Shenzhen International Arbitration Court. The arbitration shall take place in Shenzhen, Guangdong Province, China. The arbitration language shall be Chinese.",
    "Taocarts.shop用户注册协议": "Taocarts.shop User Registration Agreement",
    "Taocarts.shop用户服务协议的确认": "Confirmation of Taocarts.shop User Service Agreement",
    "Taocarts.shop用户服务相关定义": "Taocarts.shop User Service Related Definitions",
    "Taocarts.shop为您提供的服务内容": "The service content provided by Taocarts.shop for you",
    "账户的注册，使用和注销": "Registration, Use, and Cancellation of Accounts",
    "Taocarts.shop自动扣款授权": "Taocarts.shop automatic deduction authorization",
    "使用Taocarts.shop用户服务的注意事项": "Precautions for using Taocarts.shop user service",
    "用户合法使用Taocarts.shop服务的承诺": "The commitment of users to legally use the Taocarts.shop service",
    "用户权益保障及信息保护": "User rights protection and information protection",
    "不可抗力、免责及责任限制": "Force Majeure, Disclaimer, and Limitation of Liability",
    "配套规则和协议": "Supporting rules and agreements",
    "知识产权的保护": "Protection of intellectual property rights",
    "法律适用": "Legal application",
    "感谢您选择Taocarts.shop服务Taocarts.shop用户注册协议(以下简称本协议”)由Taocarts.shop(我们”)和您答订。Taocarts.shop的各项电子服务的所有权和运作权归Taocarts.shop所有，用户同意所有注册条款并完成注册程序，才能成为Taocarts.shop正式用户，本协议条款是处理双方权利义务的契约，始终有效。": "Thank you for choosing the Taocarts.shop service. The Taocarts.shop user registration agreement (hereinafter referred to as ‘this agreement’) is signed between Taocarts.shop (us) and you. The ownership and operation rights of various electronic services of Taocarts.shop belong to Taocarts.shop. Users must agree to all registration terms and complete the registration process in order to become formal users of Taocarts.shop. The terms of this agreement are a contract that governs the rights and obligations of both parties and remain valid at all times.",
    "一、Taocarts.shop用户服务协议的确认": "1、 Confirmation of Taocarts.shop User Service Agreement",
    "用户在使用Taocarts.shop所提供的各项服务的同时，承诺接受并遵守各项相关规则的规定，用户确认本服务协议后，本服务协议即在用户和Taocarts.shop之间产生法律效力，请用户务必在使用之前认真阅读全部服务协议内容，如有何疑问，您可通过Taocarts.shop官方网站的在线客服或邮箱97668216@qq.com进行咨询，以便我们为您解释和说明，您通过页面点击或其他方式确认即表示您已同意本协议。": "When users use the services provided by Taocarts.shop, they promise to accept and comply with the relevant rules and regulations. After confirming this service agreement, it will become legally binding between users and Taocarts.shop. Users must carefully read all the content of the service agreement before use. If you have any questions, you can use the online customer service or email on the official website of Taocarts.shop 97668216@qq.com Consult with us so that we can explain and explain to you. By clicking on the page or confirming through other means, you agree to this agreement.",
    "为给您提供更好的服务以及随着Taocarts.shop业务的发展，本协议也会随之更新。如我们对本协议进行修改，我们将通过在Taocarts.shop网站上发出更新版本并在生效前通过网站公告或以其他适当方式提醒您相关内容的更新。对于重大变更，我们还会提供更为显著的通知(我们会通过包括但不限于邮件短信或在浏览页面做特别提示等方式，说明本协议的具体变更内容)，若您无法同意变更修改后的协议内容，您有权停止使用相关服务;双方协商一致的，也可另行变更相关服务和对应协议内容，登录或继续使用“服务”将表示您接受并同章协议修改后的全部内容": "In order to provide you with better services and with the development of Taocarts.shop business, this agreement will also be updated accordingly. If we make any modifications to this agreement, we will issue an updated version on the Taocarts.shop website and notify you of any updates to the relevant content through website announcements or other appropriate means before it takes effect. For significant changes, we will also provide more significant notifications (including but not limited to email messages or special prompts on browsing pages to explain the specific changes in this agreement). If you cannot agree to the modified agreement content, you have the right to stop using the relevant services; If both parties reach an agreement through consultation, relevant services and corresponding agreement content can also be changed separately. Logging in or continuing to use 'services' will indicate that you accept and agree to the entire content of the modified agreement in the same chapter",
    "您需遵守您所属国家或地区的法律，目您确认，订立并履行本协议不违反您所属，所居住或开展经营活动或其他业务的国家或地区的法律法规。": "You are required to comply with the laws of your country or region. Therefore, you confirm that entering into and performing this agreement does not violate the laws and regulations of the country or region where you belong, reside, conduct business activities or other business.",
    "二、Taocarts.shop用户服务相关定义": "2、 Taocarts.shop User Service Related Definitions",
    "Taocarts.shop用户账户(或称”账户”:指在您通过平台注册并通过Taocarts.shop用户身份验证后，我们为您开立的平台使用账户。": "Taocarts.shop user account (also known as ‘account’) refers to the platform usage account we open for you after you register on the platform and verify your Taocarts.shop user identity.",
    "Taocarts.shop用户登录邮箱:指您设置的用于登录用户账户的电子邮箱。": "Taocarts.shop user login email: refers to the email address you have set up to log in to the user account.",
    "身份要素：指我们用于识别您身份的信息要素，包括但不限于您的Taocarts.shop用户账户、密码、邮箱校验码、电话号码、手机号码等信息。": "Identity elements: Refers to the information elements we use to identify you, including but not limited to your Taocarts.shop user account, password, email verification code, phone number, mobile phone number, and other information.",
    "Taocarts.shop用户网站:除本协议另有规定外，指Taocarts.shop及/或Taocarts.shop用户操作后台。": "Taocarts. shop user website: Unless otherwise specified in this agreement, refers to Taocarts. s",
    "Taocarts.shop默认免费为所有用户提供以下软件功能和服务，足以让您自助一站式开启电商事业:": "Taocarts.shop provides the following software features and services for free to all users by default, which is enough to enable you to start your e-commerce business in a self-service one-stop manner:",
    "建店/关联店铺，支持绑定Shopifv/WooCommerce/Taocarts.shop店铺平台。Taocarts.shop为您一键创建独立站店铺，同时Taocarts.shop能支持绑定您的独立站店铺并提供选择商品、订单管理、包裹管理和国际物流等全流程电商服务。": "Build/associate stores, support binding to Shopifv/WooCommerce/Taocarts.shop store platform. Taocarts.shop allows you to create a standalone store with just one click, while also supporting binding to your standalone store and providing full process e-commerce services such as product selection, order management, package management, and international logistics.",
    "海量商品搜索/上架，支持中国电商平台(淘宝、天猫、1688)。": "Massive product search/listing, supporting Chinese e-commerce platforms (Taobao, Tmall, 1688).",
    "专业的客户经理为您提供包含店铺运营，产品采购，质量检验，仓储，包裹服务，物流和售后服务的一站式解决方案。": "A professional customer manager provides you with a one-stop solution that includes store operations, product procurement, quality inspection, warehousing, parcel services, logistics, and after-sales service.",
    "(二)服务项详情": "（2） Service Item Details",
    "基础服务，基础服务是您在使用系统时涉及的服务项。Taocarts.shop为每位用户提供一定的免费次数，您需为超出的次数付费。包括对接的第三方平台，商品搜索/编辑、订单同步到Taocarts.shop全球寄送等基础服务。": "Basic services refer to the service items involved in using the system. Taocarts.shop provides each user with a certain number of free times, and you need to pay for the excess times. Including third-party platforms for docking, basic services such as product search/editing, and order synchronization to Taocarts.shop for global shipping.",
    "增值服务，增值服务仅在商品采购、仓储打包、国际物流发货过程中可能产生收费。该服务包括商品增值服务(到付件代付、修剪线头、商品盘点、商品个性化贴标等)和包裹增值服务(EPE泡沫填充物、纸护角卡条、拉伸膜、木架加固等)。": "Value added services may only incur fees during the process of product procurement, warehousing and packaging, and international logistics shipments. This service includes value-added services for goods (payment upon arrival, trimming of thread ends, inventory of goods, personalized labeling of goods, etc.) and value-added services for packages (EPE foam filler, paper corner strip, stretching film, wood frame reinforcement, etc.).",
    "店铺服务，Taocarts.shop为您提供多样化的店铺装修、商品管理、订单管理等店铺运营管理收费服务，包括店铺搭建托管、产品托管、订单托管、店铺运营托管等。服务项详情和收费标准请查看:": "Store services, Taocarts.shop provides you with a variety of store decoration, product management, order management, and other store operation and management fee services, including store building hosting, product hosting, order hosting, store operation hosting, and so on. Please refer to:",
    "(三)商品存储与销毁": "（3） Storage and destruction of goods",
    "商品存储，每一件寄送至Taocarts.shop仓库的商品，都可以享受30天的免费存储服务(从商品签收时间开始计算)。并且，在30天免费存储服务到期后，您可以继续购买付费存储服务延长存储期限，通常，我们会在存储期即将到期前3天，以及到期当天向您发送邮件提醒，请您及时完成商品寄送，或购买付费存储服务延长商品存储期。": "Product storage, every item sent to the Taocarts.shop warehouse can enjoy a 30 day free storage service (calculated from the time the product is signed for). And after the 30 day free storage service expires, you can continue to purchase paid storage services to extend the storage period. Usually, we will send you an email reminder 3 days before the expiration date and on the day of expiration, asking you to complete the product delivery in a timely manner or purchase paid storage services to extend the product storage period.",
    "商品销毁，如果您在商品存储服务已过期30天后，商品仍在仓库未寄送，目没有购买任何付费存储服务或支付相关服务费用，我们将对过期商品讲行销股，已销毁的商品将无法继续存储或寄出。通常，我们会在商品即将销毁前3天，以及销毁当天向您发送邮件提醒。商品销毁后，您将没有权利向Taocarts.shop索要已销毁的商品，或申请相关赔偿": "Destruction of goods. If you have not purchased any paid storage services or paid for related service fees after 30 days after the expiration of the goods storage service, and the goods are still in the warehouse but have not been shipped, we will sell the expired goods. The destroyed goods will not be able to be stored or shipped again. Usually, we will send you an email reminder 3 days before the goods are about to be destroyed, as well as on the day of destruction. After the goods are destroyed, you will not have the right to claim the destroyed goods from Taocarts.shop or apply for relevant compensation",
    "四、账户的注册，使用和注销": "4、 Registration, Use, and Cancellation of Accounts",
    "(一)注册": "（1） Registration",
    "当您进入我们的网站账号注册页面(https://Taocarts.shop)填写必要信息并完成注册，通过Taocarts.shop用户验证后取得Taocarts.shop用户账户，您即可使用Taocarts.shop为您提供的用户服务，享受Taocarts.shop后台相应的操作权限。您有义务在注册时提供自己的真实材料，并保证如电子邮件地址等内容的有效性和安全性保证Taocarts.shop可通过上述联系方式与您取得联系。同时，您也有义务在相关资料实际变更时及时更新注册资料，并同时保证不以他人资料在Taocarts.shop进行注册或认证。基于风险控制的需要，Taocarts.shop平台可决定是否向您提供用户账户。": "When you enter our website account registration page（ https://Taocarts.shop ）Fill in necessary information and complete registration through Taoca",
    "五、Taocarts.shop自动扣款授权": "5、 Taocarts.shop automatic deduction authorization",
    "本协议所述授权内容由您向Taocarts.shop平台承诺确认，且有授权之法律效力，请您务必审慎阅读，充分理解此条款内容，特别是免除或者限制责任的条款，您应重点阅读。除非您已阅读并接受此条款，否则您无权使用Taocarts.shop平台的自动续费、自动扣款等服务。您同意此条款即视为您已向Taocarts.shop平台提供自动扣款授权，并自愿承担由此导致的一切法律后果。如您有任何舒问，应向Taocarts.shop客服(电话+8615038350530)咨询": "The authorization content mentioned in this agreement is confirmed by your commitment to the Taocarts.shop platform, and has the legal effect of authorization. Please carefully read and fully understand the content of this clause, especially the exemption or limitation of liability clause. You should focus on reading it. Unless you have read and accepted this clause, you are not authorized to use the Taocarts.shop platform's automatic renewal, automatic deduction, and other services. By agreeing to this clause, you are deemed to have provided automatic deduction authorization to the Taocarts.shop platform and voluntarily assume all legal consequences arising from it. If you have any questions, you should consult Taocarts.shop customer service at+8615038350530",
    "Taocarts.shop平台在首次扣款时需要获取您的授权验证，您勾选授权后即视为您授权Taocarts.shop可依据您所选服务项目的交易指令从您的Taocarts.shop账户余额中扣划对应的款项月Taocarts.shop后续在扣款时，将无需再验证您的授权信息。": "The Taocarts.shop platform needs to obtain your authorization verification for the first deduction. After you check the authorization, it is deemed that you authorize Taocarts.shop to deduct the corresponding amount from your Taocarts.shop account balance based on the transaction instructions of the selected service project. Taocarts.shop will not need to verify your authorization information again during subsequent deductions.",
    "您在扣款账户内必须预留足够被扣划的余额，否则因账户余额不足导致无法及时扣款或扣款错误，失败，责任由您自行承担。因不可归责事由，导致的不能及时划账付款、划账错误等责任与Taocarts.shop平台无关": "You must reserve enough balance to be deducted in your deduction account. Otherwise, if the account balance is insufficient and the deduction cannot be made in a timely manner or in error, you will be responsible for the failure. The Taocarts.shop platform is not responsible for the inability to timely transfer payments or transfer errors caused by non attributable reasons",
    "您确认，因Taocarts.shop平台的原因导致您遭受经济损失的，由您与Taocarts.shop平台协商解决": "You confirm that if you suffer economic losses due to reasons on the Taocarts.shop platform, you will negotiate with the Taocarts.shop platform to resolve the issue",
    "六、使用Taocarts.shop用户服务的注意事项": "6、 Precautions for using Taocarts.shop user service",
    "(一)极简账号": "（1） Minimalist account",
    "(二)为有效保障您使用我们服务时的合法权益，我们提醒您注意身份验证事项：": "（2） To effectively protect your legitimate rights and interests when using our services, we remind you to pay attention to identity verification matters:",
    "八、用户权益保障及信息保护": "8、 User rights protection and information protection",
    "九、不可抗力、免责及责任限制": "9、 Force Majeure, Disclaimer, and Limitation of Liability",
    "十、配套规则和协议": "10、 Supporting rules and agreements",
    "十一、知识产权的保护": "11、 Protection of intellectual property rights",
    "十二、法律适用": "12、 Legal application",
    "填写您的店铺名称": "Fill in your store name",
    "请输入您的店铺域名": "Please enter your store domain name",
    "请输入taocarts代购站域名": "Please enter the domain name of the Taoarts purchasing website",
    "填写您的电子邮箱": "Fill in your email address",
    "填写您的店铺密码": "Fill in your store password",
    "请输入邮箱验证码": "Please enter the email verification code",
    "搜索": "search",
    "填写您的电话号码": "Fill in your phone number",
    "店铺类型": "Store Type",
    "选择最适合你的类型和套餐，开启建店第一步！": "Choose the type and package that best suits you and take the first step towards building a store!",
    "省心开店": "Easy to open a store",
    "上百种精选电商主题模板一键应用，卡片式操作，无需设计能力，快速拥有一个能提现您品牌调性、安全、稳定的在线商店": "Hundreds of selected e-commerce theme templates with one click application, card like operation, no design ability required, quickly owning an online store that can extract your brand's tone, security, and stability",
    "强大的应用生态": "Powerful application ecosystem",
    "丰富多元的应用市场，成熟完善的服务生态，提供您所需的选品供应、引流获客、物流履约、客户管理、功能拓展等多种工具和服务": "Rich and diverse application markets, mature and complete service ecology, providing you with various tools and services such as product selection and supply, customer attraction and acquisition, logistics performance, customer management, and functional expansion",
    "免费使用": "Free use",
    "一站式工作台，多语言切换的操作模式，轻松管理商店的一切事务": "One stop workbench with multi language switching operation mode, easy to manage all store affairs",
    "离开": "leave",
    "下一步": "Next step",
    "店铺商品": "Store products",
    "超多热门商品包，无需上传，一键开启你的店铺销售": "Numerous popular product packages, no need to upload, one click to start selling in your store",
    "请选择您想要进行售卖的商品种类，例如：日杂百货、原创服饰、鞋包配件、工厂直营等~": "Please select the type of product you want to sell, such as daily necessities, original clothing, shoe and bag accessories, factory direct sales, etc",
    "选择商品": "Select",
    "查看商品详情": "View product details",
    "后退": "Backward",
    "店铺模板": "Store template",
    "请选择您想要的模板~": "Please select the template you want~",
    "选择模板": "Select Template",
    "店铺信息": "Store Information",
    "编辑一个简洁好记的店铺信息，进行注册，方便用户进行检索商店": "Edit a concise and memorable store information for registration, making it easy for users to search for stores",
    "店铺名称": "Store Name",
    "必填项": "Required ",
    "创建一个简单好记的店铺名称吧~他将会置于店铺顶部": "Create a simple and memorable store name~it will be placed at the top of the store",
    "店铺域名": "Store Domain Name",
    "设置一个简洁的前缀，方便用户检索商店~他将会置于店铺顶部": "Set a concise prefix to facilitate user retrieval of the store~it will be placed at the top of the store",
    "关联的taocarts代购站域名": "The domain name of the associated Taoarts purchasing station",
    "若您已拥有taocarts代购站，可实现代购站和店铺的数据互通": "If you already have a Taoarts purchasing station, you can achieve data exchange between modern purchasing stations and stores",
    "个人信息": "personal information",
    "店铺密码": "Store password",
    "邮箱验证码": "Email verification code",
    "验证码已发送": "Verification code sent",
    "电话号码": "Telephone number",
    "区号": "Area code",
    "即表示您已同意": "That means you have agreed",
    "条款与注册协议": "Terms and Registration Agreement",
    "创建": "establish",
    "商品详情": "Product Details",
    "共有": "Co-ownership",
    "个商品": "Items",
    "商城站": "Shangcheng Station",
    "不单是建立了一个网站，而是建立了一个完整的、自动履单免维护，并且可无限扩展升级的事业": "Not only did we establish a website, but we also established a complete, automated, maintenance free, and infinitely scalable and scalable business",
    "代购站": "Purchasing station",
    "创建一个电子商务网站，帮您在线采购中国商品，系统化订单管理，促进销售并管理您的日常工作。": "Create an e-commerce website to help you purchase Chinese goods online, systematize order management, promote sales, and manage your daily work.",
    "请填写有效的域名": "Please fill in a valid domain name",
    "请填写正确的邮箱": "Please fill in the correct email address",
    "点击发送验证码": "Click to send verification code",
    "请填写店铺名称": "Please fill in the store name",
    "请填写有效的店铺域名": "Please fill in a valid store domain name",
    "请填写有效的电子邮箱": "Please fill in a valid email address",
    "请填写店铺密码": "Please fill in the store password",
    "请填写邮箱验证码": "Please fill in the email verification code",
    "请填写手机号码": "Please fill in your phone number",
    "请选择区号": "Please select an area code",
    "请选择店铺商品": "Please select a store product",
    "请选择店铺模板": "Please select a store template",
    "立即建立您的Taocarts商店": "Establish your Taoarts store now",
    "免费使用Taocarts,并开始探索、启动、运营和发展业务所需要的工具和服务": "Free use of Taoarts and start exploring, launching, operating, and developing the tools and services needed for your business",
    "开始全球销售": "Starting global sales",
    "用Taocarts工具创建您的商店，打造和发展您的品牌": "Create your store, build and develop your brand with the Taoarts tool",
    "免费开通您的taocarts在线商店，无需任何费用": "Open your Taoarts online store for free at no cost",
    "创建您的商店": "Create your store",
    "只需简单几步，就能帮助您开通taocarts跨境在线商店": "With just a few simple steps, we can help you open a Taoarts cross-border online store",
    "选择店铺类型": "Select store type",
    "选择售卖商品": "Choose to sell products",
    "选择店铺模板": "Select Store Template",
    "精选电商主题模板一键应用，卡片式操作，无需设计能力，快速拥有一个能提现您品牌调性、安全、稳定的在线商店": "Selected e-commerce theme templates with one click application, card style operation, no design ability required, quickly owning an online store that can extract your brand's tone, security, and stability",
    "编辑店铺信息": "Edit store information",
    "自由选择中国淘宝，1688产品库热门商品": "Free to choose popular products from China's Taobao 1688 product library",
    "全球销售您的产品": "Global sales of your products",
    "自由上传自营商品到taocarts商店": "Free upload of self operated products to Taoarts store",
    "在线销售": "Online sales",
    "使用Taocarts内置SEO、自动化营销工具、谷歌、Facebook等主要营销渠道集成的应用程序": "Applications integrated with major marketing channels such as Taoarts' built-in SEO, automated marketing tools, Google, Facebook, etc",
    "订单和物流管理": "Order and Logistics Management",
    "Taocarts管理和配送来自您商店的订单，向消费者发送订单物流信息，让消费者获得更好的购物体验": "Taoarts manages and delivers orders from your store, sends order logistics information to consumers, and provides them with a better shopping experience",
    "数据管理": "data management",
    "所有来自您商店的订单、财务、会员数据都可以实时查看，帮助您更好的开展业务": "All orders, finance, and membership data from your store can be viewed in real-time to help you better conduct your business",
    "在线客服": "Online Service ",
    "直接在商店里与消费者沟通，提升千倍销量，接触并留住消费者": "Communicate directly with consumers in the store, increase sales thousands of times, and engage and retain consumers",
    "业务资金更安全": "Business funds are safer",
    "可直接绑定收款账户，随时查看收款资金": "You can directly bind to the receiving account and view the receiving funds at any time",
    "查看模板详情":"View Template Details",
    "更多模板敬请期待":"Stay tuned for more templates",
    "留电咨询 获取商机":"Stay in touch for business opportunities",
    "享受蓝海 抢占先机":"Enjoy the blue ocean to seize the opportunity",
    "取消选择":"Select None",
    "商店已经准备就绪":"The store is ready",
    "电子邮箱":"Email",
    "店铺地址":"Store Address",
    "请确认基本的商店信息，之后您可以在管理中更改订阅":"Please confirm the basic store information, and then you can change the subscription in the management",
    "多达":"Up to",
    "一键上传到你的商店，销售什么商品由你来决定":"One click upload to your store, it's up to you to decide what products to sell",
    "数10种类付款方式，您的顾客在选择好商品之后，可以选择当地的安全支付方式为您快捷收款":"There are 10 types of payment methods, and after selecting the product, your customer can choose the local secure payment method to quickly receive payment for you",
    "简化订单，跟踪付款和查看实时业务，因此您可以更专注于建立您的业务":"Simplify orders, track payments, and view real-time business, so you can focus more on building your business",
    "共":"Total",
    "种商品":"Product category",
    "查看您的商店业务概述":"View an overview of your store's business",
    "管理后台":"Manage Stores",
    "点击进入我的店铺":"Click to enter my store",
    "进入我的店铺":"My shop",
    "去修改":"To modify",
    "开通":"Create",
    "请输入您的店铺地址":"Please enter your store address",
    "Taocarts 一站式":"Taocarts",
    "请阅读并同意条款":"Please read and agree to the terms",
    "请输入字母或数字做为您的店铺域名":"Please enter letters or numbers as your store domain name"
}