// Imports
import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);
//解决vue路由重复导航错误
//获取原型对象上的push函数
const originalPush = Router.prototype.push
//修改原型对象中的push方法
Router.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err)
}
const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash };
    if (savedPosition) return savedPosition;

    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      component: () => import("@/layouts/base/Index.vue"),
      children: [
        {
          path: "/",
          name: "Home",
          component: () => import("@/views/home/Home.vue")
        },  
        {
          path: "/sign-up",
          component: () => import("@/views/home/SignUp.vue")
        },
        {
          path: "/solution",
          component: () => import("@/views/home/Solution.vue")
        },
        {
          path: "/recruit",
          component: () => import("@/views/home/Recruit.vue")
        },
        {
          path: "/about-us",
          component: () => import("@/views/home/AboutUs.vue")
        },
        {
          path: "/register-agreement",
          component: () => import("@/views/home/RegisterAgreement.vue")
        },
        {
          path: "/privacy-policy",
          component: () => import("@/views/home/PrivacyPolicy.vue")
        },
        {
          path: "/success",
          component: () => import("@/views/home/Success.vue")
        },
        {
          path: "/activation",
          component: () => import("@/views/home/activation.vue")
        },
        {
          path: "/question",
          component: () => import("@/views/home/question.vue")
        },
      ]
    },
    {
      path: "/sign-in",
      component: () => import("@/views/SignIn.vue")
    },
  ]
});

export default router;
