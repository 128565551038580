import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    baseUrl:'https://api.taocarts.cc',
   // baseUrl:'http://192.168.31.139:85',
    langdef:localStorage.getItem('langdef')==''||localStorage.getItem('langdef')==undefined? 'zh-CN':localStorage.getItem('langdef'),
    USERINFO:JSON.parse(localStorage.getItem("DROPSHOPUSERINFO"))?JSON.parse(localStorage.getItem("DROPSHOPUSERINFO")):null,
    locationOrigin:location.origin
  },
  mutations: {
    setLangDef(state,langdef){
      state.langdef = langdef
      localStorage.setItem('langdef',langdef)
    },
    setUSERINFO(state,data){
      localStorage.setItem('DROPSHOPUSERINFO',JSON.stringify(data))
      state.USERINFO=JSON.parse(localStorage.getItem("DROPSHOPUSERINFO"))
    },
  },
})
