import Ajax from './ajax';
const Request = {
  shop: {
    // 获取商品包列表
    get_goods_package_list(params) {
      return Ajax.post('/Admin/shop/get_goods_package_list', params);
    },
    // 获取商品包商品列表
    get_goods_package_info_list(params) {
      return Ajax.post('/Admin/shop/get_goods_package_info_list', params);
    },
    // 获取区域码列表
    get_country_list(params) {
      return Ajax.post('/Admin/shop/get_country_list', params);
    },
    // 获取验证码
    send_code(params) {
      return Ajax.post('/Admin/common/send_code', params);
    },
    //开通店铺 
    open_shop(params) {
      return Ajax.post('/Admin/shop/open_shop', params);
    },
    // 检测店铺余名是否可用
    check_web_domain(params) {
      return Ajax.post('/Admin/shop/check_web_domain', params);
    },
    // 检测店铺邮箱是否可用
    check_account(params) {
      return Ajax.post('/Admin/shop/check_account', params);
    },
    // 获取模板列表
    get_themes_list(params) {
      return Ajax.post('/Admin/shop/get_themes_list', params);
    },
    //添加店铺
    add(params) {
      return Ajax.post('/Admin/Admin/add_shop', params)
    },

  },
  help: {
    //获取帮助中心文章列表
    get_help_list(params) {
      return Ajax.post('/Admin/Helpcenter/get_help_center_list', params)
    },
    //获取帮助中心文章详情
    get_help_detail(params) {
      return Ajax.post('/Admin/Helpcenter/get_help_center_detail', params)
    }
  },
  public:{
    getconfig(params) {
      return Ajax.post('/Admin/common/get_admin_config_info', params)
    }
  }
};
export default Request;
